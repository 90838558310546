import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  modalOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    width: 460,
    backgroundColor: "white",
    borderRadius: 16,
    overflow: "hidden",
    boxShadow: "0 25px 50px rgba(0, 0, 0, 0.25)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 16,
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#999",
    padding: 8,
  },
  modalHeader: {
    padding: "24px 24px 0",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 700,
    color: "#333",
    margin: "0 0 8px 0",
  },
  modalSubtitle: {
    fontSize: 16,
    color: "#666",
    margin: "0 0 24px 0",
    lineHeight: 1.5,
  },
  aspectRatios: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "center",
    margin: "0 24px 20px",
    position: "relative",
  },
  aspectContainer: {
    width: 120,
    height: 140,
    position: "relative",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  },
  aspectImage: {
    width: "100%",
    height: 85,
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
    overflow: "hidden",
    border: "2px solid #eee",
  },
  aspectFree: {
    backgroundColor: "#f5f5f7",
  },
  aspectPremium: {
    backgroundColor: "#fff",
    borderColor: "#ff3b5c",
    position: "relative",
    boxShadow: "0 5px 15px rgba(255, 59, 92, 0.15)",
    "&::before": {
      content: '"PREMIUM"',
      position: "absolute",
      top: -10,
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "#ff3b5c",
      color: "white",
      fontSize: 10,
      fontWeight: 600,
      padding: "4px 8px",
      borderRadius: 4,
      letterSpacing: "0.5px",
    },
  },
  aspectRatioDemo: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(45deg, #ff597d, #ff8066)",
    borderRadius: 4,
  },
  aspectLabel: {
    textAlign: "center",
    marginTop: 12,
    fontSize: 14,
    fontWeight: 600,
    color: "#333",
  },
  aspectTag: {
    display: "block",
    fontSize: 12,
    color: "#666",
    marginTop: 4,
    fontWeight: "normal",
  },
  modalFeatures: {
    display: "flex",
    margin: "0 24px 24px",
    gap: theme.spacing(1.5),
  },
  feature: {
    flex: 1,
    textAlign: "center",
    padding: "16px 12px",
    backgroundColor: "#f8f9fa",
    borderRadius: 12,
  },
  featureIcon: {
    color: "#ff3b5c",
    marginBottom: 8,
  },
  featureTitle: {
    fontSize: 14,
    fontWeight: 600,
    margin: 0,
    color: "#444",
  },
  modalActions: {
    display: "flex",
    padding: 24,
    borderTop: "1px solid #eee",
    gap: theme.spacing(1.5),
  },
  btn: {
    flex: 1,
    padding: "14px 20px",
    borderRadius: 12,
    fontSize: 16,
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    transition: "all 0.2s ease",
    textAlign: "center",
  },
  btnCancel: {
    backgroundColor: "#333",
    color: "white",
    "&:hover": {
      backgroundColor: "#444",
    },
  },
  btnUpgrade: {
    backgroundColor: "#ff3b5c",
    color: "white",
    boxShadow: "0 4px 12px rgba(255, 59, 92, 0.25)",
    "&:hover": {
      backgroundColor: "#ff1a42",
      transform: "translateY(-2px)",
      boxShadow: "0 8px 16px rgba(255, 59, 92, 0.3)",
    },
  },
}));
