import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  "@global": {
    "@keyframes fadeIn": {
      from: {
        opacity: 0,
        transform: "translateY(10px)",
      },
      to: {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },

  tooltip: {
    position: "absolute",
    top: "2.5rem",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 9999,

    [theme.breakpoints.down("sm")]: {
      left: "-1rem",
      top: "2.5rem",
      transform: "none",
    },
  },

  tooltipContent: {
    position: "relative",
    width: "320px",
    background: "linear-gradient(145deg, #2c2c2c, #1a1a1a)",
    borderRadius: "12px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.25)",
    padding: "24px",
    color: "#fff",
    animation: "fadeIn 0.3s ease-out",

    "&::after": {
      content: '""',
      position: "absolute",
      top: "-10px",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #2c2c2c",
    },

    [theme.breakpoints.down("sm")]: {
      "&::after": {
        left: "20px",
        transform: "none",
      },
    },
  },

  tooltipTitle: {
    fontSize: "18px",
    fontWeight: 700,
    margin: "0 0 16px 0",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "10px",
    },
  },

  featureList: {
    margin: "0 0 20px 0",
    padding: 0,
    listStyle: "none",
  },

  featureItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    fontSize: "14px",
    "& svg": {
      color: "#ff597d",
      marginRight: "10px",
      flexShrink: 0,
    },
  },

  ctaButton: {
    background: "linear-gradient(90deg, #ff597d, #ff3b5c)",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    fontSize: "15px",
    fontWeight: 600,
    padding: "12px 0",
    width: "100%",
    cursor: "pointer",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 6px 15px rgba(255, 89, 125, 0.4)",
    },
  },

  dismiss: {
    position: "absolute",
    top: "12px",
    right: "12px",
    background: "transparent",
    border: "none",
    color: "rgba(255, 255, 255, 0.6)",
    cursor: "pointer",
    padding: "5px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      color: "#fff",
    },
  },
}));
