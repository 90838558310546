import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  "@global": {
    "@keyframes slideUp": {
      "0%": {
        opacity: 0,
        transform: "translateY(15px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
  tooltip: {
    position: "absolute",
    top: "3rem",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 9999,

    [theme.breakpoints.down("sm")]: {
      left: "-1rem",
      top: "2.5rem",
      transform: "none",
    },
  },

  tooltipContent: {
    width: 320,
    background: "linear-gradient(145deg, #2c2c2c, #1a1a1a)",
    borderRadius: 12,
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.6)",
    padding: 24,
    position: "relative",
    color: "#fff",
    animation: "$slideUp 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)",

    "&::after": {
      content: '""',
      position: "absolute",
      top: -10,
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #2c2c2c",
    },
    [theme.breakpoints.down("sm")]: {
      "&::after": {
        left: "20px",
        transform: "none",
      },
    },
  },
  dismiss: {
    position: "absolute",
    top: 5,
    right: 5,
    background: "transparent",
    border: "none",
    color: "rgba(255, 255, 255, 0.6)",
    cursor: "pointer",
    padding: 5,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      color: "#fff",
    },
  },
  progressContainer: {
    marginBottom: 20,
  },
  progressLabel: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    fontSize: 13,
    color: "rgba(255, 255, 255, 0.7)",
  },
  progressBar: {
    height: 6,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 3,
    overflow: "hidden",
  },
  progressFill: {
    height: "100%",
    width: "85%",
    background: "linear-gradient(90deg, #ff597d, #ff3b5c)",
    borderRadius: 3,
  },

  // Title + subtitle
  tooltipTitle: {
    fontSize: 18,
    fontWeight: 700,
    margin: "0 0 8px 0",
    color: "#fff",
  },
  tooltipSubtitle: {
    fontSize: 14,
    color: "rgba(255, 255, 255, 0.85)",
    margin: "0 0 24px 0",
    lineHeight: 1.5,
  },
  ctaButton: {
    background: "linear-gradient(90deg, #ff597d, #ff3b5c)",
    color: "#fff",
    border: "none",
    borderRadius: 8,
    fontSize: 15,
    fontWeight: 600,
    padding: "12px 0",
    width: "100%",
    cursor: "pointer",
    transition: "transform 0.2s, box-shadow 0.2s",
    letterSpacing: 0.5,

    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 6px 15px rgba(255, 89, 125, 0.4)",
    },
  },

  // Footer
  tooltipFooter: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
    fontSize: 12,
    color: "rgba(255, 255, 255, 0.6)",

    "& a": {
      color: "#ff597d",
      textDecoration: "none",
      marginLeft: 5,
    },
  },
}));
