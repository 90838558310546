"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { useStyles } from "./styles";

interface AspectRatioModalProps {
  onClose: () => void;
  onUpgrade: () => void;
}

const AspectRatioModal: React.FC<AspectRatioModalProps> = ({
  onClose,
  onUpgrade,
}) => {
  const { classes } = useStyles();
  const router = useRouter();

  const handleCancel = () => {
    onClose();
  };

  const handleUpgrade = () => {
    onUpgrade();
    router.push("/subscribe");
  };

  return (
    <div className={classes.modalOverlay} onClick={handleCancel}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        {/* Close Button */}
        <button className={classes.closeButton} onClick={onClose}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        {/* Banner */}
        <div className={classes.modalBanner}>
          <div className={classes.bannerShapes}>
            <div className={`${classes.ratioShape} ${classes.shape1}`} />
            <div className={`${classes.ratioShape} ${classes.shape2}`} />
            <div className={`${classes.ratioShape} ${classes.shape3}`} />
          </div>
          <div className={classes.bannerContent}>
            <h2 className={classes.bannerTitle}>Unlock All Aspect Ratios</h2>
            <p className={classes.bannerSubtitle}>
              Perfect dimensions for every creative project
            </p>
          </div>
        </div>

        {/* Modal Content */}
        <div className={classes.modalContent}>
          {/* Ratio Cards */}
          <div className={classes.ratioCards}>
            {/* Square (Free) */}
            <div className={classes.ratioCard}>
              <div className={classes.ratioIcon}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    stroke="#333"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <h4 className={classes.ratioName}>Square</h4>
              <p className={classes.ratioDesc}>1:1 Ratio</p>
              <div
                className={`${classes.ratioPreview} ${classes.squarePreview}`}
              >
                <div className={classes.previewInner} />
              </div>
            </div>

            {/* Widescreen (Premium) */}
            <div className={`${classes.ratioCard} ${classes.premium}`}>
              <div className={classes.premiumBadge}>PRO</div>
              <div className={classes.ratioIcon}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3"
                    y="5"
                    width="18"
                    height="14"
                    rx="2"
                    stroke="#333"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <h4 className={classes.ratioName}>Widescreen</h4>
              <p className={classes.ratioDesc}>16:9 Ratio</p>
              <div
                className={`${classes.ratioPreview} ${classes.widescreenPreview}`}
              >
                <div className={classes.previewInner} />
                <div className={classes.lockIcon}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11M12 15V17M7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>

            {/* Portrait (Premium) */}
            <div className={`${classes.ratioCard} ${classes.premium}`}>
              <div className={classes.premiumBadge}>PRO</div>
              <div className={classes.ratioIcon}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="5"
                    y="3"
                    width="14"
                    height="18"
                    rx="2"
                    stroke="#333"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <h4 className={classes.ratioName}>Portrait</h4>
              <p className={classes.ratioDesc}>4:5 Ratio</p>
              <div
                className={`${classes.ratioPreview} ${classes.portraitPreview}`}
              >
                <div className={classes.previewInner} />
                <div className={classes.lockIcon}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11M12 15V17M7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.featuresGrid}>
            <div className={classes.featureItem}>
              <div className={classes.featureIcon}>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={classes.featureText}>
                Easily switch between 10 Aspect Ratios
              </div>
            </div>
            <div className={classes.featureItem}>
              <div className={classes.featureIcon}>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={classes.featureText}>
                Get the perfect framing for Instagram, YouTube thumbnails,
                posters, and more.
              </div>
            </div>
            <div className={classes.featureItem}>
              <div className={classes.featureIcon}>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={classes.featureText}>
                Total creative control—no more awkward cropping or guesswork.
              </div>
            </div>
          </div>

          <div className={classes.modalActions}>
            <button
              className={`${classes.btn} ${classes.btnCancel}`}
              onClick={handleCancel}
            >
              CANCEL
            </button>
            <button
              className={`${classes.btn} ${classes.btnUpgrade}`}
              onClick={handleUpgrade}
            >
              UPGRADE NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AspectRatioModal;
