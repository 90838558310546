import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modal: {
    width: 420,
    backgroundColor: "#fff",
    borderRadius: 16,
    overflow: "hidden",
    boxShadow: "0 25px 50px rgba(0, 0, 0, 0.25)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: 12,
    right: 12,
    background: "rgba(0, 0, 0, 0.2)",
    border: "none",
    width: 28,
    height: 28,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    cursor: "pointer",
    zIndex: 10,
  },
  modalBanner: {
    height: 110,
    background: "linear-gradient(135deg, #ff3b5c, #ff7676)",
    position: "relative",
    overflow: "hidden",
  },
  bannerShapes: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  ratioShape: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: 8,
  },
  shape1: {
    width: 50,
    height: 50,
    top: 20,
    left: 30,
    transform: "rotate(15deg)",
  },
  shape2: {
    width: 70,
    height: 55,
    top: 35,
    left: 120,
    transform: "rotate(-10deg)",
  },
  shape3: {
    width: 60,
    height: 40,
    top: 15,
    right: 40,
    transform: "rotate(5deg)",
  },
  bannerContent: {
    position: "absolute",
    bottom: -10,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#fff",
    padding: "10px 20px",
    borderRadius: 12,
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    minWidth: 240,
  },
  bannerTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: "#333",
    margin: "0 0 4px",
  },
  bannerSubtitle: {
    fontSize: 12,
    color: "#666",
    margin: 0,
  },
  modalContent: {
    padding: "24px 20px 20px",
  },
  ratioCards: {
    display: "flex",
    gap: 12,
    marginBottom: 24,
  },
  ratioCard: {
    flex: 1,
    padding: 10,
    backgroundColor: "#f8f9fa",
    borderRadius: 12,
    textAlign: "center",
    position: "relative",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  },
  premium: {
    backgroundColor: "rgba(255, 59, 92, 0.1)",
    border: "1px solid rgba(255, 59, 92, 0.3)",
  },
  ratioIcon: {
    width: 50,
    height: 50,
    margin: "0 auto 8px",
    position: "relative",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  premiumBadge: {
    position: "absolute",
    top: -8,
    right: -8,
    backgroundColor: "#ff3b5c",
    color: "#fff",
    fontSize: 10,
    fontWeight: 600,
    padding: "3px 6px",
    borderRadius: 4,
    zIndex: 1,
  },
  ratioName: {
    fontSize: 14,
    fontWeight: 600,
    color: "#333",
    margin: "0 0 3px",
  },
  ratioDesc: {
    fontSize: 11,
    color: "#666",
    margin: 0,
  },
  ratioPreview: {
    margin: "8px auto 0",
    width: "90%",
    height: 0,
    position: "relative",
  },
  squarePreview: {
    paddingBottom: "100%",
  },
  portraitPreview: {
    paddingBottom: "125%",
  },
  widescreenPreview: {
    paddingBottom: "56.25%",
  },
  previewInner: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(45deg, #ff597d, #ff8066)",
    borderRadius: 6,
  },
  lockIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "rgba(255, 255, 255, 0.9)",
    fontSize: 20,
  },
  featuresGrid: {
    backgroundColor: "#f8f9fa",
    borderRadius: 12,
    padding: 12,
    marginBottom: 20,
  },
  featureItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  featureIcon: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "rgba(255, 59, 92, 0.15)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    color: "#ff3b5c",
    flexShrink: 0,
  },
  featureText: {
    fontSize: 12,
    color: "#333",
  },
  modalActions: {
    display: "flex",
    gap: 12,
  },
  btn: {
    flex: 1,
    padding: "10px 14px",
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    transition: "all 0.2s ease",
    textAlign: "center",
  },
  btnCancel: {
    backgroundColor: "#333",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#444",
    },
  },
  btnUpgrade: {
    backgroundColor: "#ff3b5c",
    color: "#fff",
    boxShadow: "0 4px 12px rgba(255, 59, 92, 0.25)",
    "&:hover": {
      backgroundColor: "#ff1a42",
      transform: "translateY(-2px)",
      boxShadow: "0 8px 16px rgba(255, 59, 92, 0.3)",
    },
  },
}));
