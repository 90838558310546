"use client";

import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "redux/store";
import { setSelectedAspectRatio } from "redux/actions";

import { fetchCtaVersions, postCtaView, postCtaConversion } from "utils/cta";
import PopoverButton from "./PopoverButton";
import AspectRatioIcon from "./AspectRatioIcon";
import { TransformationType } from "@/types/thread";
import PaywallA from "components/common/PaywallCTA/AspectRatioPaywall/PaywallA";
import PaywallB from "components/common/PaywallCTA/AspectRatioPaywall/PaywallB";

// All possible aspect ratios
export const ALL_ASPECT_RATIOS = [
  "9:16",
  "3:4",
  "2:3",
  "1:1",
  "5:4",
  "4:3",
  "3:2",
  "7:4",
  "16:9",
  "21:9",
];

// Subset for Flux Pro
export const FLUX_PRO_ASPECT_RATIOS = ["9:16", "3:4", "1:1", "4:3", "16:9"];

const AspectRatioSelect: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const selectedModel = useAppSelector((state) => state.room.selectedModel);
  const selectedAspectRatio = useAppSelector(
    (state) => state.room.selectedAspectRatio
  );

  const availableAspectRatios =
    selectedModel === TransformationType.Flux_Pro
      ? FLUX_PRO_ASPECT_RATIOS
      : ALL_ASPECT_RATIOS;

  const handleSelect = (newAspectRatio: string) => {
    dispatch(setSelectedAspectRatio(newAspectRatio));
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("selectedAspectRatio", newAspectRatio);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [ctaVersion, setCtaVersion] = useState("A");

  useEffect(() => {
    if (!isSubscribed) {
      fetchCtaVersions("aspect_ratio_on_click", setCtaVersion);
    }
  }, [isSubscribed]);

  const handleClick = async () => {
    if (!isSubscribed) {
      await postCtaView("aspect_ratio_on_click", ctaVersion);
      setShowModal(true);
      return;
    }
  };

  const handleUpgrade = async () => {
    await postCtaConversion("aspect_ratio_on_click", ctaVersion);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div onClick={handleClick}>
      <PopoverButton
        selected={selectedAspectRatio}
        options={availableAspectRatios.map((ratio) => {
          const [w, h] = ratio.split(":").map(Number);
          return {
            value: ratio,
            icon: (
              <AspectRatioIcon
                w={w}
                h={h}
                active={selectedAspectRatio === ratio}
                disabled={!isSubscribed}
                aria-label="Aspect Ratio Select"
              />
            ),
            label: "",
          };
        })}
        onSelect={handleSelect}
        aspectRatioCount={availableAspectRatios.length}
        disabled={!isSubscribed}
      />

      {showModal && ctaVersion === "A" && (
        <PaywallA onClose={closeModal} onUpgrade={handleUpgrade} />
      )}
      {showModal && ctaVersion === "B" && (
        <PaywallB onClose={closeModal} onUpgrade={handleUpgrade} />
      )}
    </div>
  );
};

export default AspectRatioSelect;
