"use client";

import React, { FC } from "react";
import { useRouter } from "next/navigation";
import { useStyles } from "./styles";

interface ChatBubbleProps {
  onClose: () => void;
  onUpgrade: () => void;
}

const ChatBubble: FC<ChatBubbleProps> = ({ onClose, onUpgrade }) => {
  const router = useRouter();
  const { classes } = useStyles();

  return (
    <div className={classes.tooltip}>
      <div className={classes.tooltipContent}>
        <button className={classes.dismiss} onClick={onClose}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <h3 className={classes.tooltipTitle}>
          <svg
            width="0"
            height="0"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 9L15.6716 9.17157C17.0049 10.5049 17.6716 11.1716 17.6716 12C17.6716 12.8284 17.0049 13.4951 15.6716 14.8284L15.5 15"
              stroke="#ff597d"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M13.2929 7.29289L9.29289 11.2929L10.7071 12.7071L14.7071 8.70711L13.2929 7.29289Z"
              fill="#ff597d"
            />
          </svg>
          Unlock HD Generation
        </h3>
        <ul className={classes.featureList}>
          <li className={classes.featureItem}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13L9 17L19 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Crisp, high-resolution images
          </li>
          <li className={classes.featureItem}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13L9 17L19 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Priority generation queue
          </li>
          <li className={classes.featureItem}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13L9 17L19 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Unlimited daily generations
          </li>
        </ul>
        <button
          className={classes.ctaButton}
          onClick={() => {
            onUpgrade();
            router.push("/subscribe");
          }}
        >
          UPGRADE NOW
        </button>
      </div>
    </div>
  );
};

export default ChatBubble;
