"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { useStyles } from "./styles";

interface PaywallUpgradeModalProps {
  onClose: () => void;
  onUpgrade: () => void;
}

const PaywallUpgradeModal: React.FC<PaywallUpgradeModalProps> = ({
  onClose,
  onUpgrade,
}) => {
  const router = useRouter();
  const { classes } = useStyles();

  const handleCancel = () => {
    onClose();
  };

  const handleUpgrade = () => {
    onUpgrade();
    router.push("/subscribe");
  };

  return (
    <div className={classes.modalOverlay} onClick={(e) => e.stopPropagation()}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        <button className={classes.closeButton} onClick={onClose}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <div className={classes.modalHeader}>
          <h2 className={classes.modalTitle}>Subscription Required</h2>
          <p className={classes.modalSubtitle}>
            Unlock all aspect ratios to create perfectly sized images for any
            project
          </p>
        </div>

        <div className={classes.aspectRatios}>
          <div className={classes.aspectContainer}>
            <div className={`${classes.aspectImage} ${classes.aspectFree}`}>
              <div style={{ paddingBottom: "100%" }}>
                <div className={classes.aspectRatioDemo} />
              </div>
            </div>
            <p className={classes.aspectLabel}>
              1:1 <span className={classes.aspectTag}>Standard</span>
            </p>
          </div>

          <div className={classes.aspectContainer}>
            <div className={`${classes.aspectImage} ${classes.aspectPremium}`}>
              <div style={{ paddingBottom: "56.25%" }}>
                <div className={classes.aspectRatioDemo} />
              </div>
            </div>
            <p className={classes.aspectLabel}>
              16:9 <span className={classes.aspectTag}>Widescreen</span>
            </p>
          </div>

          <div className={classes.aspectContainer}>
            <div className={`${classes.aspectImage} ${classes.aspectPremium}`}>
              <div style={{ paddingBottom: "75%" }}>
                <div className={classes.aspectRatioDemo} />
              </div>
            </div>
            <p className={classes.aspectLabel}>
              4:3 <span className={classes.aspectTag}>Classic</span>
            </p>
          </div>
        </div>

        <div className={classes.modalFeatures}>
          <div className={classes.feature}>
            <div className={classes.featureIcon}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3"
                  y="3"
                  width="18"
                  height="18"
                  rx="2"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path d="M3 7H21" stroke="currentColor" strokeWidth="2" />
              </svg>
            </div>
            <h4 className={classes.featureTitle}>10+ Aspect Ratios</h4>
          </div>
          <div className={classes.feature}>
            <div className={classes.featureIcon}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8Z"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M7 12H17"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12 7L12 17"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <h4 className={classes.featureTitle}>Custom Dimensions</h4>
          </div>
        </div>

        <div className={classes.modalActions}>
          <button
            className={`${classes.btn} ${classes.btnCancel}`}
            onClick={handleCancel}
          >
            CANCEL
          </button>
          <button
            className={`${classes.btn} ${classes.btnUpgrade}`}
            onClick={handleUpgrade}
          >
            UPGRADE
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaywallUpgradeModal;
