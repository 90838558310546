"use client";

import React, { FC } from "react";
import { useStyles } from "./styles";
import { useRouter } from "next/navigation";

interface UpgradeTooltipProps {
  onClose: () => void;
  onUpgrade: () => void;
}

const UpgradeTooltip: FC<UpgradeTooltipProps> = ({ onClose, onUpgrade }) => {
  const { classes } = useStyles();
  const router = useRouter();

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  const handleUpgrade = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onUpgrade();
    router.push("/subscribe");
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div className={classes.tooltip} onClick={stopPropagation}>
      <div className={classes.tooltipContent}>
        <button className={classes.dismiss} onClick={handleCancel}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <div className={classes.progressContainer}>
          <div className={classes.progressLabel}>
            <span>Free Account</span>
            <span>Premium</span>
          </div>
          <div className={classes.progressBar}>
            <div className={classes.progressFill} />
          </div>
        </div>

        <h3 className={classes.tooltipTitle}>You&apos;re almost there!</h3>
        <p className={classes.tooltipSubtitle}>
          Upgrade now to unlock HD generation and create stunning
          high-resolution images without limits.
        </p>

        <button className={classes.ctaButton} onClick={handleUpgrade}>
          UPGRADE
        </button>

        <div className={classes.tooltipFooter}>
          <span>No commitments, cancel anytime.</span>
        </div>
      </div>
    </div>
  );
};

export default UpgradeTooltip;
