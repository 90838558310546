"use client";

import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { BsFillBadgeHdFill, BsBadgeHd } from "react-icons/bs";
import HdrPlusIcon from "@mui/icons-material/HdrPlus";

import { fetchCtaVersions, postCtaView, postCtaConversion } from "utils/cta";
import { useStyles } from "./styles";
import { TransformationType } from "@/types/thread";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setSelectedModel, setSelectedAspectRatio } from "@/redux/actions";
import ControlButton from "components/idea/GenInputs/Controls/ControlButton";
import ChatBubble from "components/common/PaywallCTA/HDChatBubbleCTA/PaywallA";
import UpgradeTooltip from "components/common/PaywallCTA/HDChatBubbleCTA/PaywallB";

const HdToggle = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pathname = usePathname();
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const selectedModel = useAppSelector((state) => state.room.selectedModel);
  const quotas = useAppSelector((state) => state.user.quotas);
  const hdQuota = quotas.find((quota) => quota.resourceName === "hd");
  const hasRemainingHdQuota = hdQuota && hdQuota.currentQuota > 0;
  const chatBubbleAvailable = Boolean(
    user && !isSubscribed && pathname !== "/subscribe"
  );

  const [wiggle, setWiggle] = useState(false);
  const [showPaywall, setShowPaywall] = useState(false);
  const [ctaVersion, setCtaVersion] = useState("A");

  useEffect(() => {
    if (!user || isSubscribed) return;
    fetchCtaVersions("hd_unlock_on_hover", setCtaVersion);
  }, [user, isSubscribed]);

  useEffect(() => {
    if (!user || isSubscribed) return;

    const triggerWiggle = () => {
      setWiggle(true);
      setTimeout(() => setWiggle(false), 1500);
      const randomInterval = Math.random() * (10000 - 5000) + 5000;
      setTimeout(triggerWiggle, randomInterval);
    };

    const initialRandomInterval = Math.random() * (10000 - 5000) + 5000;
    const timer = setTimeout(triggerWiggle, initialRandomInterval);
    return () => {
      clearTimeout(timer);
    };
  }, [user, isSubscribed]);

  const handleUpgrade = async () => {
    setShowPaywall(false);
    await postCtaConversion("hd_unlock_on_hover", ctaVersion);
    router.push("/subscribe");
  };

  const handleToggle = () => {
    if (!isSubscribed && !hasRemainingHdQuota) {
      setShowPaywall(true);
      return;
    }

    let newValue = TransformationType.Turbo;
    if (selectedModel === TransformationType.Turbo) {
      if (isSubscribed || hasRemainingHdQuota) {
        newValue = TransformationType.HD;
      }
    } else if (selectedModel === TransformationType.HD && isSubscribed) {
      newValue = TransformationType.Flux_Pro;
    }

    dispatch(setSelectedModel(newValue));
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("selectedModel", newValue);
    }

    if (newValue === TransformationType.Flux_Pro) {
      dispatch(setSelectedAspectRatio("1:1"));
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("selectedAspectRatio", "1:1");
      }
    }
  };

  const handleShowPaywall = async () => {
    setShowPaywall(true);
    await postCtaView("hd_unlock_on_hover", ctaVersion);
  };

  const getButtonProps = () => {
    switch (selectedModel) {
      case TransformationType.HD:
        return {
          icon: <BsBadgeHd />,
          activeIcon: <BsFillBadgeHdFill />,
          isActive: true,
          tooltip: "Enable HDR+",
        };
      case TransformationType.Flux_Pro:
        return {
          icon: <HdrPlusIcon />,
          activeIcon: <HdrPlusIcon />,
          isActive: isSubscribed,
          tooltip: "Disable HDR+",
        };
      default:
        return {
          icon: <BsBadgeHd />,
          activeIcon: <BsFillBadgeHdFill />,
          isActive: false,
          tooltip: showPaywall ? "" : "Enable HD",
        };
    }
  };

  const buttonProps = getButtonProps();

  return (
    <div className={classes.container} onMouseEnter={handleShowPaywall}>
      {showPaywall && chatBubbleAvailable && (
        <>
          {ctaVersion === "A" ? (
            <ChatBubble
              onClose={() => setShowPaywall(false)}
              onUpgrade={handleUpgrade}
            />
          ) : (
            <UpgradeTooltip
              onClose={() => setShowPaywall(false)}
              onUpgrade={handleUpgrade}
            />
          )}
        </>
      )}
      <ControlButton
        label="HD Toggle"
        disabled={!isSubscribed && !hasRemainingHdQuota}
        onClick={handleToggle}
        className={wiggle ? classes.wiggleAnimation : ""}
        {...buttonProps}
      />
    </div>
  );
};

export default HdToggle;
